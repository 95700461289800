

export function rafThrottle(fn) { 
	let busy = false;
	return () => {
		if (busy) return
		busy = true
		// call the function, and remove 'busy' status, after the next render
		fn.apply(this, arguments) 
		window.requestAnimationFrame(function() {
			busy = false;
		})
	}
}


export function addScrollListener(elem, callback) {

	if (!elem) return
	let vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

	function tick() {
		const rect = elem.getBoundingClientRect()
		const scroll = Math.max(0, vh - rect.top)
		const scrollEnd = vh + rect.height
		const percent = Math.min(1, Math.max(0, scroll / scrollEnd))
		if (percent >= 1 || percent <= 0) return
		// 0% = off screen, below viewport
		// 100% = off screen, above viewport
		callback(percent, rect)
	}

	tick(); // do initial tick on load

	window.addEventListener('scroll', rafThrottle(tick))
	window.addEventListener('resize', rafThrottle(() => {
		vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
		tick()
	}));
}


export const lerp = (v0, v1, t) => v0 * (1-t) + v1*t


export function createMoveHandler(el) {
	const inner = el.querySelector('.parallax__inner')
	return (percent, rect) => window.requestAnimationFrame(() => {
		// start the animation at -20% transform, and move to 0
		// which centers the image over the course of the animation
		// without showing any overlap (css is set to 120% height)
		const transformY = lerp(rect.height*-0.3, 0, percent)
		inner.style.transform = `translateY(${transformY}px)`;
	})
}


export function init() {
	const elems = [...document.querySelectorAll('.parallax')]
	elems.forEach((el) => {
		const scrollHandler = createMoveHandler(el)
		addScrollListener(el, scrollHandler)
	})
}
