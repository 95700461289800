


export function init() {

	const selectors = {
		el : '.moreinfo',
		main : '.moreinfo__main',
			button : '.moreinfo__button',
		info : '.moreinfo__info',
			buttonBack: '.moreinfo__info__back'
	}

	const elems = [...document.querySelectorAll(selectors.el)]

	elems.forEach((el) => {

		const main = el.querySelector(selectors.main)
		const infos = [...el.querySelectorAll(selectors.info)]
		const buttons = [...main.querySelectorAll(selectors.button)]
		const backButtons = [...el.querySelectorAll(selectors.buttonBack)]

		// open button clicks
		buttons.forEach(button => {
			button.addEventListener('click', (event) => {
				const name = event.currentTarget.getAttribute('data-moreinfo-name')
				showInfo(name, infos, main)
			})
		})

		// hide button clicks
		backButtons.forEach(button => button.addEventListener('click', () => hideInfo(infos, main)))
	})
}



export function showInfo(name, infos, main) {
	const info = infos.filter((info) => (info.getAttribute('data-moreinfo-name') === name))[0] || false
	if (!info) return false
	hideMain(main)
	info.classList.add('moreinfo__info--in');
}

export function hideInfo(infos, main) {
	infos.forEach(info => info.classList.remove('moreinfo__info--in'));
	showMain(main)
}


export function showMain(el) {
	el.classList.remove('moreinfo__main--out');
}

export function hideMain(el) {
	el.classList.add('moreinfo__main--out');
}
